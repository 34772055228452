<template>
<div class="mt-4">
    <h5>Not Found Errors</h5>
    <div class="d-flex">
        <b-button class="m-3" @click="testPatient404">
            Patient 404
        </b-button>
        <b-button class="m-3" @click="testPreauth404">
            Preauth 404
        </b-button>
        <b-button class="m-3" @click="testPractice404">
            Practice 404
        </b-button>
    </div>
    <h5>Access Forbidden</h5>
    <b-button class="m-3" @click="test403">
        Raise 403
    </b-button>
    <h5>Method Not Allowed</h5>
    <b-button class="m-3" @click="test405">
        POST to a GET (405)
    </b-button>
    <h5>General Server Error</h5>
    <b-button class="m-3" @click="test500">
        Raise 500
    </b-button>
</div>
</template>


<script>
import { make_url, authenticated_request } from '../common.js'

export default {
  name: 'TestErrors',
  components: {},
  data(){
      return {
      }
  },
  methods: {
    testPatient404: function(){
        const path = "/tests/errors/patients/404";
        const url = new URL(path, process.env.VUE_APP_API_URL);
        const promise = authenticated_request({
            method: "get",
            url: url,
        }).then(function(response){
            return response
        });
        
        return promise;
    },
    testPreauth404: function(){
        const path = "/tests/errors/preauths/404";
        const url = new URL(path, process.env.VUE_APP_API_URL);
        const promise = authenticated_request({
            method: "get",
            url: url,
        }).then(function(response){
            return response
        });
        
        return promise;
    },
    testPractice404: function(){
        const path = "/tests/errors/practices/404";
        const url = new URL(path, process.env.VUE_APP_API_URL);
        const promise = authenticated_request({
            method: "get",
            url: url,
        }).then(function(response){
            return response
        });
        
        return promise;
    },
    test403: function(){
        const path = "/tests/errors/403";
        const url = new URL(path, process.env.VUE_APP_API_URL);
        const promise = authenticated_request({
            method: "get",
            url: url,
        }).then(function(response){
            return response
        });
        
        return promise;
    },
    test405: function(){
        const url = make_url("/reports/notes");
        const promise = authenticated_request({
            method: "post",
            url: url,
            params: {}
        }).then(function(response){
            return response
        });
        return promise;
    },
    test500: function(){
        const path = "/tests/errors/500";
        const url = new URL(path, process.env.VUE_APP_API_URL);
        const promise = authenticated_request({
            method: "get",
            url: url,
        }).then(function(response){
            return response
        });
        
        return promise;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>