<template>
<div>
    <div>
        <div>
            <show-general-errors />
            <h1>Component Demo</h1>
        </div>
        <activity-list claimId="1" tableTitle="My Activity List Table" />
        <activity-list-embedded claimId="1"/>
        <div>
            <h2> AvoAlert: </h2>
            <avo-alert :avoAlerts.sync="avoAlerts"/>
        </div>
        <div>
            <h2>Number formatting</h2>
            formatNumber
            {{ formatNumber(1234.56789) }} <br />
            formatNumber
            {{ formatNumber(1234.5) }} <br />
            formatDollars
            {{ formatDollars(1234.5) }} <br />
            formatDollars
            {{ formatDollars(1234.56789) }} <br />
            formatDollars no cents
            {{ formatDollars(1234.5, false) }} <br />
            formatDollars no cents
            {{ formatDollars(1234.56789, false) }} <br />
            formatDecimal
            {{ formatDecimal(1234.56789) }} <br />
            formatDecimal
            {{ formatDecimal(1234.5) }} <br />
        </div>
        <div>
            <h2>General Error Handling Demo</h2>
            <test-errors />
        </div>
        <div>
            <taxonomy-cSV-upload />
        </div>
    </div>
    
    <div id="footer"></div>
</div>
</template>

<script>
import { alertsMixin, formatDollars, formatNumber, formatDecimal } from '../common'
import ActivityList from '../components/activity/ActivityList.vue'
import ActivityListEmbedded from '../components/activity/ActivityListEmbedded.vue'
import ShowGeneralErrors from "../components/ShowGeneralErrors.vue"
import TestErrors from "../components/TestErrors.vue"
import TaxonomyCSVUpload from "../components/documents/TaxonomyCSVUpload.vue"
import AvoAlert from "../components/AvoAlert.vue"

export default {
  name: 'Demo',
  mixins: [alertsMixin],
  components: {
      AvoAlert,
      ActivityList,
      ActivityListEmbedded,
      ShowGeneralErrors,
      TestErrors,
      TaxonomyCSVUpload
  },
  data(){
      return {
          publicPath: process.env.BASE_URL
      }
  },
  mounted() {
      this.showAvoSuccess("This is a great success!");
      this.showAvoAlert("This is an alert!");
      this.showAvoInfo("This is an info message");
  },
  methods: {
    formatDollars,
    formatNumber,
    formatDecimal,
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
    font-family: sans-serif;
    display: inline;
}
</style>